import React, { Component } from 'react';

function Home() {
  return (
    <main className='Home'>
      <h1>Home</h1>
    </main>
  );
}

export default Home;
